import { FileType } from '@home-in/models'
import { decryptWithAes256, encryptWithAes256 } from '@home-in/utilities/dist/crypto'
import to from 'await-to-js'
import axios from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { TextField, ListboxV2 } from '@elements/forms'
import { MAX_FILE_SIZE } from '@elements/forms/constants'
import { Icon, Icons } from '@elements/icons/icon'
import LoadingSpinner from '@elements/loading-spinner'
import { useGetSignature } from '@hooks/useGetSignature'
import { useHandleCaughtErrors } from '@hooks/useHandleCaughtErrors'
import { useGetSignedUrlMutation } from '@redux/apis/documents'
import { trackEvent, trackRequestFailure, trackRequestStart, trackRequestSuccess } from '@redux/reducers/analytics'
import {
  AnalyticsCategories,
  AnalyticsEventAPICallEventNames,
  TrackAnalyticEventNames,
} from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'
import { formatBytes } from '@utils/helpers/string.helpers'

export type UploadState = 'uploading' | 'complete' | 'error'

export const GenericFile = ({
  fileId,
  file,
  onDelete,
  onComplete,
  formMethods,
  hbjId,
  pbId,
}: {
  fileId: string
  file: File
  onDelete: (fileId: string) => void
  onComplete: (fileId: string) => void
  formMethods: UseFormReturn
  hbjId: string
  pbId: string
}) => {
  const dispatch = useDispatch()
  const { control, register } = formMethods
  const fileTypesArray: string[] = Object.values(FileType)
  const [uploadState, setUploadState] = React.useState<UploadState>('uploading')
  const [getSignedUrl] = useGetSignedUrlMutation()
  const splitName = file.name.split(/\.(?=[^\.]+$)/)
  const tooLarge = file.size > MAX_FILE_SIZE
  const wrongFileType = !fileTypesArray.includes(splitName[1]?.toLowerCase())
  const logError = useHandleCaughtErrors()
  const signature = useGetSignature()
  const { enableMaterialUiForms } = useFlags()

  const CATEGORIES = ['Contract', 'Finance Approval', 'Other Conditional Approvals', 'Completed Form', 'Others']

  useEffect(() => {
    if (!signature) return
    if (tooLarge || wrongFileType) {
      dispatch(
        trackEvent({
          action: TrackAnalyticEventNames.UploadDocFileSelectionInvalid,
          category: AnalyticsCategories.Documents,
          value: { fileName: file.name, fileSize: file.size },
        })
      )
      setUploadState('error')
      return
    }
    const uploadFile = async () => {
      dispatch(
        trackRequestStart({
          action: AnalyticsEventAPICallEventNames.GetGenericUploadUrl,
          category: AnalyticsCategories.Documents,
        })
      )
      const [signedUrlErr, signedUrl] = await to(
        getSignedUrl({
          hbjId,
          propertyId: pbId,
          fileName: splitName[0],
          fileType: splitName[1]?.toLowerCase() as FileType,
          attachmentCategory: 'Other documents',
        }).unwrap()
      )
      if (signedUrlErr) {
        dispatch(
          trackRequestFailure({
            action: AnalyticsEventAPICallEventNames.GetGenericUploadUrl,
            category: AnalyticsCategories.Documents,
            value: JSON.stringify(signedUrlErr),
          })
        )
        logError({ code: 'S3UploadFailure_getSignedUrlError', message: JSON.stringify(signedUrlErr) })
        return setUploadState('error')
      }

      const decryptedUrl = decryptWithAes256(signedUrl!, signature) || ''
      dispatch(
        trackRequestStart({
          action: AnalyticsEventAPICallEventNames.UploadGenericDocS3,
          category: AnalyticsCategories.Documents,
        })
      )
      const [uploadFileError, uploadFileResponse] = await to(axios.put<{ url: string }>(decryptedUrl, file))

      if (uploadFileError) {
        dispatch(
          trackRequestFailure({
            action: AnalyticsEventAPICallEventNames.UploadGenericDocS3,
            category: AnalyticsCategories.Documents,
            value: JSON.stringify(uploadFileError),
          })
        )
        logError({ code: 'S3UploadFailure_fileUploadError', message: JSON.stringify(uploadFileError) })
        return setUploadState('error')
      }

      const encryptedUrl = encryptWithAes256(uploadFileResponse?.request.responseURL, signature) || ''
      setUploadState('complete')
      dispatch(
        trackRequestSuccess({
          action: AnalyticsEventAPICallEventNames.UploadGenericDocS3,
          category: AnalyticsCategories.Documents,
          value: { fileName: file.name, fileSize: file.size },
        })
      )
      formMethods.setValue(`file-url-${fileId}`, encryptedUrl)
      onComplete(fileId)
    }

    uploadFile().catch()
  }, [signature])

  return (
    <CSSTransition
      timeout={200}
      classNames={{
        enter: 'opacity-0',
        enterActive: 'opacity-100 transition duration-200',
        exitActive: 'opacity-0 transition duration-200',
      }}
    >
      <div className="my-2 rounded bg-background p-2">
        <input type={'hidden'} {...register(`file-url-${fileId}`, { shouldUnregister: true })} />
        <div className="relative z-10 flex items-center justify-between">
          <div className="relative flex items-center overflow-hidden">
            {uploadState === 'uploading' && <LoadingSpinner className="ml-2 mr-4 h-4 w-4 min-w-fit" />}
            {uploadState === 'complete' && (
              <Icon asset={Icons.checkmark} className="ml-2 mr-4 min-w-fit shrink-0 text-success" size={14} />
            )}
            {uploadState === 'error' && (
              <Icon asset={Icons.warning} className="ml-2 mr-4 min-w-fit shrink-0 text-error" size={14} />
            )}
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">{splitName[0]}</div>
          </div>
          <div className="relative flex shrink-0 items-center justify-between">
            <div className={`px-2 ${wrongFileType && 'text-error-300'}`}>{splitName[1]?.toUpperCase()}</div>
            <div className={`min-w-fit px-2 ${tooLarge && 'text-error-300'}`}>{formatBytes(file.size)}</div>
            <div
              onClick={() => onDelete(fileId)}
              role="button"
              tabIndex={-1}
              className="cursor-pointer p-2 text-muted transition hover:bg-error-100 active:bg-error-300 active:text-white"
            >
              <Icon asset={Icons.cancel} size={12} />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'relative mb-8 grid grid-cols-1 gap-4 px-2 md:grid-cols-2',
            enableMaterialUiForms && 'mt-4'
          )}
        >
          <ListboxV2
            label={enableMaterialUiForms ? 'Select file category' : ''}
            control={control}
            values={CATEGORIES.map((c) => ({ name: c, value: c }))}
            name={`file-category-${fileId}`}
            floatingLabel={true}
            defaultEmpty={true}
            placeholder={!enableMaterialUiForms ? 'Select file category *' : ''}
            required={true}
            hasRequiredLabel={false}
            size="sm"
            className="py-0"
          />
          <TextField
            label={enableMaterialUiForms ? 'Description' : ''}
            name={`file-description-${fileId}`}
            control={control}
            required={false}
            placeholder={!enableMaterialUiForms ? 'Description' : ''}
            size="small"
            className="py-0"
          />
        </div>
        <div className="-mt-6 px-2 text-sm text-error">
          {wrongFileType ? 'File type not supported' : tooLarge ? 'File size too large' : ''}
        </div>
      </div>
    </CSSTransition>
  )
}
