import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import useActiveHbj from '@hooks/useActiveHbj'
import { useAppSelector } from '@redux/hooks'

export const filterProperties = (
  properties: WaratahPropertyWithChecklist[] | null | undefined,
  removedChecklists: WaratahPropertyWithChecklist[],
  activeHbjId: string | undefined
) => {
  const filteredProperties = properties?.filter((checklist) => {
    const propertyIsFromCurrentHbj = checklist?.hbjId === activeHbjId
    const propertyShouldBeRemoved = !!removedChecklists?.find(
      (removedChecklist) =>
        removedChecklist?.propertyId === checklist?.propertyId && removedChecklist?.hbjId === checklist?.hbjId
    )

    return propertyIsFromCurrentHbj && !propertyShouldBeRemoved
  })

  return filteredProperties
}

export const useCurrentJourneyChecklists = () => {
  const properties = useAppSelector((state) => state.checklist.checklists)
  const removedChecklists = useAppSelector((state) => state.checklist.removedChecklists)
  const { activeHbjId } = useActiveHbj()

  const filteredProperties = filterProperties(properties, removedChecklists, activeHbjId)

  return filteredProperties
}
