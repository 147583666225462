import { dispatchDataToMammoth } from '../../components/helpers/app-data-bridge'

export const extractFileName = (s3SignedUrl: string): string => {
  const url = new URL(s3SignedUrl)
  const fileName = url.pathname.split('/')[url.pathname.split('/').length - 1]
  return fileName
}

export const downloadFileInWeb = (url: string, filename: string): void => {
  fetch(url)
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobURL
      document.body.appendChild(link)
      link.setAttribute('download', filename)
      link.click()
      link.remove()
    })
    .catch((error) => console.log(error))
}
export const downloadFileInMobile = (url: string, filename: string): void => {
  dispatchDataToMammoth(JSON.stringify({ action: 'storeOriginalFileName', args: [filename] }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
