import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useMounted } from '@hooks/lifecycleHooks'
import { classNames } from '@utils/helpers/classNameHelper'

interface ProfilePictureProps {
  img?: string
  name: string | undefined
  variant?: keyof typeof styles
  className?: string
}

const sizes = {
  normal: {
    width: 40,
    height: 40,
  },
  sm: { width: 32, height: 32 },
  xs: { width: 24, height: 24 },
  reversed: { width: 48, height: 48 },
  teamCard: { width: 48, height: 48 },
  teamCardAlt: { width: 48, height: 48 },
  teamMemberPage: { width: 80, height: 80 },
}

const styles = {
  normal: 'w-10 h-10',
  sm: 'w-8 h-8 text-sm',
  xs: 'w-6 h-6 text-xs',
  reversed: 'w-12 h-12 mr-0',
  teamCard: 'w-12 h-12',
  teamCardAlt: 'w-12 h-12 mr-3',
  teamMemberPage: 'w-20 h-20 text-lg',
}

export const ProfilePicture = ({ img, name, variant = 'normal', className }: ProfilePictureProps) => {
  const [loaded, setLoaded] = useState(false)
  const mounted = useMounted()
  const onload = () => setLoaded(true)
  const [initials, setInitials] = useState('')
  const [colourIndex, setColourIndex] = useState(0)

  useEffect(() => {
    if (name) {
      const initials = name
        .split(' ')
        .filter((_, index, arr) => {
          return index === 0 || index === arr.length - 1
        })
        .map((string) => string[0])
        .join('')

      setInitials(initials)
    }
  }, [name])

  useEffect(() => {
    setColourIndex((initials.charCodeAt(0) + (initials.charCodeAt(1) || 0)) % colours.length)
  }, [initials])

  const colours = ['bg-muted', 'bg-secondary-variant', 'bg-primary-dark', 'bg-secondary-dark']
  return (
    <div
      className={classNames(
        'overflow-hidden relative font-normal shrink-0 mr-4 rounded-full text-white',
        styles[variant],
        colours[colourIndex],
        className
      )}
      role="presentation"
    >
      {!loaded && <div className="flex h-full w-full items-center justify-center text-center">{initials}</div>}
      {img && mounted && (
        <div className={classNames('duration-200', loaded ? 'opacity-100' : 'opacity-0')}>
          <Image
            src={img}
            onLoadingComplete={onload}
            alt=""
            {...sizes[variant]}
            loader={({ src }) => src}
            unoptimized
          />
        </div>
      )}
    </div>
  )
}
