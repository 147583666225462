import { documentsApi } from '@redux/apis/documents'
import { fireToastNotifications } from '@redux/reducers/notifications'

export const toastNotifySuccess = (successMessage: string) => {
  return fireToastNotifications({ result: Promise.resolve(true), successMessage })
}

export const toastNotifyFailure = (errorMessage: string) => {
  return fireToastNotifications({ result: Promise.reject(), errorMessage })
}

export const refetchDocuments = () => {
  return documentsApi.util.invalidateTags(['Documents'])
}
