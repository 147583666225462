import { FileType } from '@home-in/models'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { FileStatus } from '@elements/file-upload/generic-file-upload'
import { Icon, Icons } from '@elements/icons/icon'
import { trackEvent } from '@redux/reducers/analytics'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { AnalyticsCategories, TrackAnalyticEventNames } from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'

const acceptedFileTypesArray = Object.keys(FileType)
const acceptedFileTypesString = acceptedFileTypesArray.join(', ')

enum FileSelectorVariant {
  generic,
  normal,
}

export const FileSelector = <T extends File | FileStatus = File>({
  multiple = true,
  multipleMax = 10,
  setFiles,
  files,
  text = 'Upload file or document',
  variant = 'normal',
}: {
  multiple?: boolean
  multipleMax?: number
  files: T[]
  setFiles: React.Dispatch<React.SetStateAction<T[]>>
  text?: string
  variant?: keyof typeof FileSelectorVariant
}) => {
  const [draggedOver, setDraggedOver] = useState(false)
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const validateFileCount = (files: T[]) => {
    if ((!multiple && files.length === 1) || files.length <= multipleMax) return true
    dispatch(
      fireToastNotifications({
        result: new Promise((_, reject) => reject()),
        errorMessage: `Sorry, you can only upload ${multipleMax} files at a time`,
      })
    )
    return false
  }

  const handleClick = () => {
    fileUploadRef.current?.click()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const items = [...e.dataTransfer.items]
    let droppedFiles = items
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter((file) => !!file) as T[]
    if (!multiple && droppedFiles.length > 1) {
      alert('Only uploading one file is supported')
    }
    if (variant === 'generic') {
      droppedFiles = droppedFiles.map((file) => ({
        file: file as File,
        fileId: uuidv4(),
        uploaded: false,
      })) as T[]
    }
    const result = multiple ? [...droppedFiles, ...files] : [droppedFiles[0]]
    if (validateFileCount(result)) setFiles(result)
    setDraggedOver(false)
    fileSelectionEvent(result)
  }

  const handleDragState = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDraggedOver(e.type === 'dragenter' || e.type === 'dragover')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedFiles = Array.from(e.target.files || []) as T[]

    if (variant === 'generic') {
      selectedFiles = selectedFiles.map((file) => ({
        file: file as File,
        fileId: uuidv4(),
        uploaded: false,
      })) as T[]
    }
    const result = [...selectedFiles, ...files]
    if (validateFileCount(result)) setFiles(result)

    fileSelectionEvent(result)
  }

  const fileSelectionEvent = (files: T[]) => {
    dispatch(
      trackEvent({
        action: TrackAnalyticEventNames.UploadDocFileSelection,
        category: variant === 'generic' ? AnalyticsCategories.Documents : AnalyticsCategories.Checklist,
        value: { count: files.length },
      })
    )
  }

  //clear our input if there's no files
  if (files.length === 0 && fileUploadRef.current) fileUploadRef.current.value = ''

  return (
    <div>
      <input
        className="hidden"
        type="file"
        name="files"
        multiple={multiple}
        ref={fileUploadRef}
        onChange={handleChange}
        accept={acceptedFileTypesString}
      />
      {(files.length === 0 || variant === 'generic') && (
        <div
          className={classNames(
            'px-4 py-4 transition flex justify-center items-center relative border-2 rounded border-background border-dashed cursor-pointer w-full',
            draggedOver && 'bg-secondary-variant-100 border-double'
          )}
          onDragEnter={handleDragState}
          onDragLeave={handleDragState}
          onDrop={handleDrop}
          onDragOver={handleDragState}
          onClick={handleClick}
        >
          <Icon className="mr-2 text-secondary" asset={Icons.attach} size={18} />
          <div className="text-secondary">{!draggedOver ? text : 'Drop files to upload'}</div>
        </div>
      )}
    </div>
  )
}
