import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { GenericError, GenericErrorType } from '@elements/status-handlers/generic-error'
import { Card } from '../card'
import LoadingSpinner from '../loading-spinner'

type LoadingWrapperProps = {
  isLoading: boolean
  isError?: boolean
  className?: string
  spinnerClassName?: string
  error?: GenericErrorType | GenericErrorType[]
  customErrorMessage?: string | ((err: GenericErrorType) => JSX.Element)
  withRefreshButton?: boolean
  variant?: 'normal' | 'thin'
}

const GenericLoadingWrapper = ({
  isLoading,
  children,
  className = '',
  isError = false,
  error,
  customErrorMessage,
  variant = 'normal',
  withRefreshButton = true,
  spinnerClassName = '',
}: PropsWithChildren<LoadingWrapperProps>) => {
  const Thin = ({ children }: PropsWithChildren<{}>) => (
    <div className={classNames('flex flex-wrap justify-center my-2', className)}>{children}</div>
  )

  const Element: (props: PropsWithChildren<{ className?: string } & any>) => JSX.Element =
    variant === 'thin' ? Thin : Card

  return !isError ? (
    <>
      {isLoading ? (
        <Element className={className}>
          <div className="flex justify-center">
            <LoadingSpinner className={classNames('h-12 w-12', spinnerClassName)} />
          </div>
        </Element>
      ) : (
        <>{children}</>
      )}
    </>
  ) : (
    <GenericError
      errors={Array.isArray(error) ? error : [error]}
      customErrorMessage={customErrorMessage}
      element={Element}
      withRefreshButton={withRefreshButton}
    />
  )
}

export const LoadingWrapper = (props: PropsWithChildren<LoadingWrapperProps>) => {
  return <GenericLoadingWrapper {...props} variant="normal" />
}

export const LoadingThinWrapper = (props: PropsWithChildren<LoadingWrapperProps>) => {
  return <GenericLoadingWrapper {...props} variant="thin" />
}
