import Image from 'next/image'
import React from 'react'
import { classNames } from '@utils/helpers/classNameHelper'
import { ProfilePicture } from './profile-picture'

export const TeamIcon = ({ iconUrl, name, className }: { iconUrl?: string; name: string; className?: string }) => {
  return (
    <div
      className={classNames(
        'relative flex overflow-hidden shrink-0 self-center ml-auto mr-0 w-6 h-6 rounded-full',
        className
      )}
    >
      {iconUrl ? (
        <Image src={iconUrl} layout="fill" alt="banner-icon" className="h-10 w-10" unoptimized />
      ) : (
        <ProfilePicture name={name} variant="xs" />
      )}
    </div>
  )
}
