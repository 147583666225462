import { ErrorSeverity } from '@home-in/models'
import { useAppSelector } from '@redux/hooks'
import { sendClientLog } from '@utils/helpers/error.helpers'

export const useHandleCaughtErrors = () => {
  const auth0id = useAppSelector((state) => state.auth.user?.sub)
  const route = useAppSelector((state) => state.page.route)

  return (err: Record<string, any>) => {
    sendClientLog({
      auth0id,
      host: window?.location?.host,
      message: JSON.stringify(err),
      route,
      severity: ErrorSeverity.ERROR,
    }).then()
  }
}
