import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import React, { useRef, useState } from 'react'
import type { Placement } from '@floating-ui/react'

interface TooltipProps {
  label: string
  placement?: Placement
  children: JSX.Element
  disabled?: boolean
}

export const Tooltip = ({ children, label, placement = 'bottom', disabled = false }: TooltipProps) => {
  const [open, setOpen] = useState(false)
  const arrowRef = useRef<HTMLDivElement>(null)

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: floatingPlacement,
    middlewareData: { arrow: arrowData },
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip({ fallbackPlacements: ['bottom'] }), shift(), size(), arrow({ element: arrowRef })],
  })

  // Event listeners to change the open state
  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const click = useClick(context, { ignoreMouse: true })
  const dismiss = useDismiss(context)
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, focus, dismiss, role])

  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[floatingPlacement?.split('-')[0]] || ''

  return (
    <>
      <button ref={reference} {...getReferenceProps()}>
        {children}{' '}
      </button>
      <FloatingPortal>
        {open && !disabled && (
          <div
            className="z-50 max-w-[400px] rounded-lg bg-muted p-4 text-sm font-normal text-white"
            ref={floating}
            style={{
              // Positioning styles
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {label}
            <div
              ref={arrowRef}
              className="bg-muted"
              style={{
                position: 'absolute',
                width: '8px',
                height: '8px',
                left: arrowData?.x != null ? `${arrowData?.x}px` : '',
                top: arrowData?.y != null ? `${arrowData?.y}px` : '',
                [staticSide]: '-4px',
                transform: 'rotate(45deg)',
              }}
            ></div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}
