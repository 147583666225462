import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import { Button } from '@elements/button'
import { Card } from '@elements/card'
import { SupportEmailLink } from '@elements/forms/link'
import { prettyPrintJson } from '@utils/helpers/string.helpers'

export type GenericErrorType = FetchBaseQueryError | SerializedError | string | undefined
type ErrorTypes = GenericErrorType[]

export const GenericError = ({
  errors,
  customErrorMessage,
  element = Card,
  withRefreshButton = true,
}: {
  errors?: ErrorTypes
  customErrorMessage?: string | ((err: GenericErrorType) => JSX.Element)
  element?: ({}: PropsWithChildren<{ className?: string } & any>) => JSX.Element
  withRefreshButton?: boolean
}) => {
  const router = useRouter()
  const Element = element

  const filteredErrors = errors?.filter((error) => !!error)

  return (
    <>
      <Element className="w-full text-center">
        <div className="mb-4 w-full text-center text-muted">
          <p className="mb-2 font-emoji text-2xl">⚠️</p>
          {customErrorMessage && typeof customErrorMessage === 'string' && <p>{customErrorMessage}</p>}
          {customErrorMessage && customErrorMessage instanceof Function && (
            <>
              {filteredErrors?.map((err) => (
                <>{customErrorMessage(err)}</>
              ))}
            </>
          )}
          {!customErrorMessage && (
            <>
              <p>Sorry, something went wrong.</p>
              <p>
                If the issue persists, please contact <SupportEmailLink />
              </p>
            </>
          )}
        </div>
        {withRefreshButton && <Button callback={() => router.reload()}>Refresh</Button>}
      </Element>
      {process.env.NEXT_PUBLIC_APP_ENV !== 'production' &&
        process.env.NEXT_PUBLIC_APP_ENV !== 'staging' &&
        filteredErrors?.map((err, idx) => (
          <Element className="mt-4 overflow-auto text-clip" key={idx}>
            <p className="w-full font-bold">Error Log:</p>
            <pre className="mt-4 w-full whitespace-pre-wrap">{prettyPrintJson(err)}</pre>
          </Element>
        ))}
    </>
  )
}
