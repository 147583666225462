export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const capitalise = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1)

export const prettyPrintJson = (data: any): string => {
  return !data ? '' : JSON.stringify(data, null, 2)
}

/**
 * Transform snake case string to space separated capitalised string
 * @param snakeCasedString
 * @returns space separated capitalised string
 */
export const snakeCaseToSpaceCapitalised = (snakeCasedString: string): string =>
  snakeCasedString
    .split('_')
    .map((item) => capitalise(item))
    .join(' ')

export const formatCurrency = (amount: number, accuracy = 2): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: accuracy,
    maximumFractionDigits: accuracy,
  })

  return formatter.format(amount)
}

export const splitFileName = (fileName: string) => fileName.split(/\.(?=[^\.]+$)/)

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}
