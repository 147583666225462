import { useEffect, useState } from 'react'
import { jwt } from '@utils/helpers/jwt.helpers'

export const useGetSignature = () => {
  const [signature, setSignature] = useState<string>('')

  useEffect(() => {
    ;(async () => {
      const accessToken = await jwt.getAccessTokenSilently?.()?.()
      const [, , signature] = accessToken.split('.')
      setSignature(signature ?? '')
    })()
  }, [])

  return signature
}
