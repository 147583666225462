import { DocumentV2 } from '@home-in/models'
import { decryptWithAes256 } from '@home-in/utilities/dist/crypto'
import { to } from 'await-to-js'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MUIAlert } from '@elements/alert'
import { Button } from '@elements/button'
import { Card } from '@elements/card'
import { GenericFileUpload } from '@elements/file-upload/generic-file-upload'
import { Icon, Icons } from '@elements/icons/icon'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import LoadingSpinner from '@elements/loading-spinner'
import { TeamIcon } from '@elements/team-icon'
import { Tooltip } from '@elements/tooltip'
import useActiveHbj from '@hooks/useActiveHbj'
import { useCurrentJourneyChecklists } from '@hooks/useCurrentJourneyChecklists'
import { useGetSignature } from '@hooks/useGetSignature'
import {
  useGetDocumentListByPropertyIdQuery,
  useGetDocumentListQuery,
  useGetDocumentSignedUrlMutation,
} from '@redux/apis/documents'
import { useGetAllSellChecklistsQuery } from '@redux/apis/sell-checklist'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { formatToShortDate } from '@utils/helpers/date.helpers'
import { downloadFileInMobile, downloadFileInWeb } from '@utils/helpers/file-download.helpers'
import { refetchDocuments, toastNotifyFailure, toastNotifySuccess } from '@utils/helpers/reduxAction.helpers'

export function excludeLegalDocuments(documents: DocumentV2[]): DocumentV2[] {
  const legalDocuments = ['Home-in Privacy Policy', 'Application Terms and Conditions']
  return documents.filter((document) => !legalDocuments.includes(document.filename))
}

export const DocumentsComponent = () => {
  const dispatch = useDispatch()
  const { sellSideAddProperty } = useFlags()
  const { activeHbjId: hbjId = '' } = useActiveHbj()
  const { data = { propertyDocuments: [], supportDocuments: [], otherDocuments: [] }, isLoading } =
    useGetDocumentListQuery(hbjId, { pollingInterval: 5 * 60 * 1000, skip: !hbjId })

  // Get sell property HSJ, document list
  const { data: sellChecklists } = useGetAllSellChecklistsQuery()
  const sellPropertiesExist = sellChecklists && sellChecklists?.length > 0
  const sellChecklist = sellChecklists?.find((item) => item.pegaHsjId) || undefined
  const {
    data: dataSell = { propertyDocuments: [], supportDocuments: [], otherDocuments: [] },
    isLoading: isLoadingSell,
  } = useGetDocumentListByPropertyIdQuery(sellChecklist?.propertyId || '', {
    pollingInterval: 5 * 60 * 1000,
    skip: !sellSideAddProperty || !sellChecklist?.propertyId,
  })

  const buyAndSellPropertyDocuments = [...data.propertyDocuments, ...dataSell.propertyDocuments]
  const currentJourneyProperties = useCurrentJourneyChecklists() || []
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(true)
    dispatch(
      trackButtonClick({ action: ButtonClickEventNames.OpenUploadGenericDocs, category: AnalyticsCategories.Documents })
    )
  }

  const dismissCall = () => {
    setShowModal(false)
  }

  const sortDocuments = (documents: DocumentV2[]) => {
    const docs: DocumentV2[] = JSON.parse(JSON.stringify(documents))
    docs.sort((a, b) => ((a.uploadedDate || '') < (b.uploadedDate || '') ? 1 : -1))
    return docs
  }

  return (
    <>
      {isLoading || isLoadingSell ? (
        <Card className="flex w-full justify-center">
          <LoadingSpinner className="h-12 w-12" />
        </Card>
      ) : (
        <>
          {sellSideAddProperty && sellPropertiesExist && (
            <MUIAlert variant={'standard'} severity={'info'} size={'md'}>
              <div>
                <strong>Unable to upload documents for sell properties</strong>
                <div className="mt-2">
                  At this time, we do not support uploading documents for sell properties. Please email your documents
                  to{' '}
                  <a href="mailto:support@home-in.com.au" className="text-secondary-500">
                    support@home-in.com.au
                  </a>
                  .
                </div>
              </div>
            </MUIAlert>
          )}
          <div className="flex items-center py-2">
            <h6 className="mt-4 mb-4">Property Documents</h6>
            {currentJourneyProperties.length > 0 && (
              <>
                <GenericFileUpload active={showModal} toggler={dismissCall} />
                <Button size="sm" category="secondary" className="ml-auto" variant="outlined" callback={handleClick}>
                  Upload documents
                </Button>
              </>
            )}
          </div>
          <Card className="mb-8 p-0 md:p-0">
            {buyAndSellPropertyDocuments.length === 0 && <div className="flex py-4 px-4">No documents</div>}
            {buyAndSellPropertyDocuments.map(({ address, documents }) => (
              <div key={address}>
                <div className="sticky top-14 z-20 bg-light/80 py-4 px-3 font-bold backdrop-blur-lg backdrop-saturate-150 md:top-[7.5rem]">
                  {address}
                </div>
                {sortDocuments(documents).map((document) => (
                  <DocumentItem key={document.url} {...document} />
                ))}
              </div>
            ))}
          </Card>
          <h6 className="mb-4">My Documents</h6>
          <Card className="mb-8 p-0 md:p-0">
            {data.supportDocuments.length === 0 && <div className="flex py-4 px-4">No documents</div>}
            {sortDocuments(data.supportDocuments).map((document) => (
              <DocumentItem key={document.url} {...document} />
            ))}
          </Card>
          <h6 className="mb-4">Other Documents</h6>
          <Card className="mb-8 p-0 md:p-0">
            {data.otherDocuments.length === 0 && <div className="flex py-4 px-4">No documents</div>}
            {/* Excluding privacy policy and terms and conditions since it's out of date. Added new links to the profile. */}
            {excludeLegalDocuments(sortDocuments(data.otherDocuments)).map((document) => (
              <DocumentItem key={document.url} {...document} />
            ))}
          </Card>
        </>
      )}
    </>
  )
}

export const DocumentItem = ({ filename, url, uploadedDate, uploadedBy }: DocumentV2) => {
  const signature = useGetSignature()
  const dispatch = useAppDispatch()
  const [getPresignedUrl, { isLoading }] = useGetDocumentSignedUrlMutation()
  const profile = useAppSelector((state) => state.profile)
  const usersName = `${profile?.first_name} ${profile?.last_name}`
  const uploadedDisplayName = uploadedBy === 'webapiuser' ? usersName : uploadedBy
  const platform = typeof window !== 'undefined' ? window._platform || 'web' : 'web'
  const isUserOnMobileApp = platform === 'mobile'

  const handleDownload = async () => {
    const [getFileError, presignedUrl] = await to(getPresignedUrl(url))
    if (getFileError || 'error' in presignedUrl) {
      dispatch(refetchDocuments())
      dispatch(toastNotifyFailure('File download failed, please try again in 5 seconds'))
      return
    }

    const decryptedDownloadUrl = decryptWithAes256(presignedUrl.data, signature)
    if (decryptedDownloadUrl === undefined) {
      dispatch(toastNotifyFailure('File download failed'))
      return
    }

    isUserOnMobileApp
      ? downloadFileInMobile(decryptedDownloadUrl, filename)
      : downloadFileInWeb(decryptedDownloadUrl, filename)
    if (url.startsWith('documents/pega?')) {
      dispatch(refetchDocuments())
    }

    dispatch(toastNotifySuccess('File download started'))
  }
  return (
    <>
      {isLoading ? (
        <Card className="flex w-full justify-center py-2 md:py-2">
          <LoadingSpinner className="h-10 w-12" />
        </Card>
      ) : (
        <div
          className="flex cursor-pointer items-center border-b border-muted/10 py-4 px-4 transition last:border-transparent hover:bg-light/50"
          onClick={handleDownload}
          data-testid="DownloadIcon"
        >
          <Illustration asset={Illustrations.document} size={24} className="mr-3 min-w-[24px]" />
          <div className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap">{filename}</div>
          <div className="mr-0 ml-auto flex items-center">
            {uploadedDate && (
              <span className="mr-2 whitespace-nowrap text-sm text-muted-300 md:mr-4">
                {' '}
                {formatToShortDate(uploadedDate)}
              </span>
            )}
            {uploadedDisplayName && (
              <Tooltip placement="left" label={`Uploaded by ${uploadedDisplayName}`}>
                <TeamIcon name={uploadedDisplayName} className="mr-2 md:mr-4" />
              </Tooltip>
            )}
            <div className="rounded-full p-1 hover:bg-white">
              <Icon asset={Icons.download} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const Documents = memo(DocumentsComponent)
