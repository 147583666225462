import { GenericFilesUploadRequest } from '@home-in/models'
import React, { FormEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@elements/button'
import { FileSelector } from '@elements/file-upload/file-selector'
import { GenericFile } from '@elements/file-upload/generic-file'
import { DEFAULT_LISTBOX, Listbox } from '@elements/forms/fields/listbox'
import { Modal } from '@elements/modal'
import { useMobileQuery } from '@hooks/layoutHooks'
import useActiveHbj from '@hooks/useActiveHbj'
import { useCurrentJourneyChecklists } from '@hooks/useCurrentJourneyChecklists'
import { useRegisterGenericDocumentsWithPegaMutation } from '@redux/apis/documents'
import { useAppDispatch } from '@redux/hooks'
import { trackButtonClick, trackRequestFailure, trackRequestSuccess } from '@redux/reducers/analytics'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'

export type FileStatus = {
  file: File
  fileId: string
  uploaded: boolean
}

export const GenericFileUpload = ({ active, toggler }: { active: boolean; toggler: () => void }) => {
  const dispatch = useAppDispatch()
  const [fileStatus, setFileStatus] = useState<FileStatus[]>([])
  const [modalTitle, setModalTitle] = useState<string>('')
  const isMobile = useMobileQuery()
  const currentJourneyProperties = useCurrentJourneyChecklists() || []
  const [submitFiles, { isLoading: isSubmitLoading, isSuccess: isSubmitSuccess, error: submitError }] =
    useRegisterGenericDocumentsWithPegaMutation()
  const { activeHbjId } = useActiveHbj()
  const formMethods = useForm()
  const { control, watch } = formMethods
  const currentProperty = watch('property')
  const userHasMultipleProperties = currentJourneyProperties.length > 1
  const isPropertySelected = currentProperty && currentProperty !== DEFAULT_LISTBOX

  useEffect(() => {
    if (currentJourneyProperties.length > 1) {
      isPropertySelected
        ? setModalTitle(currentJourneyProperties.find((p) => p.propertyId === currentProperty)!.address)
        : setModalTitle('Please select a property')
    } else setModalTitle(currentJourneyProperties?.[0]?.address)
  }, [currentProperty, currentJourneyProperties, isPropertySelected])

  useEffect(() => {
    if (isSubmitSuccess) {
      dispatch(
        trackRequestSuccess({
          action: AnalyticsEventAPICallEventNames.SubmitGenericDocs,
          category: AnalyticsCategories.Documents,
          value: { count: fileStatus.length },
        })
      )
      toggler()
    }
  }, [isSubmitSuccess])

  useEffect(() => {
    if (submitError) {
      dispatch(
        trackRequestFailure({
          action: AnalyticsEventAPICallEventNames.SubmitGenericDocs,
          category: AnalyticsCategories.Documents,
          value: JSON.stringify(submitError),
        })
      )
    }
  }, [submitError])

  const handleDelete = (fileId: string) => {
    setFileStatus((prevState) => prevState.filter((f) => f.fileId !== fileId))
  }

  const handleComplete = (fileId: string) => {
    setFileStatus((prevState) => prevState.map((f) => (f.fileId === fileId ? { ...f, uploaded: true } : f)))
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const allValues = formMethods.getValues()

    const request: GenericFilesUploadRequest = {
      hbjId: activeHbjId!,
      pbId: currentProperty || currentJourneyProperties[0].propertyId,
      files: fileStatus.map((f) => ({
        category: allValues[`file-category-${f.fileId}`],
        fileDescription: allValues[`file-description-${f.fileId}`],
        preSignedUrl: allValues[`file-url-${f.fileId}`],
      })),
    }
    const result = submitFiles(request)
    dispatch(fireToastNotifications({ result, successMessage: 'Upload successful', errorMessage: 'Upload failed' }))
    dispatch(
      trackButtonClick({
        action: AnalyticsEventAPICallEventNames.SubmitGenericDocs,
        category: AnalyticsCategories.Documents,
        value: { files: request.files.map((f) => ({ category: f.category, description: f.fileDescription })) },
      })
    )
  }

  const isValid = useMemo(() => {
    return fileStatus.length > 0 && fileStatus.every((f) => f.uploaded) && formMethods.formState.isValid
  }, [fileStatus, formMethods.formState.isValid])

  const dismissCall = () => {
    if (fileStatus.length === 0) {
      toggler()
      return
    }
    const confirm = window.confirm('By leaving, all progress will be lost. Do you want to continue?')
    if (confirm) {
      toggler()
    }
  }

  return (
    <div>
      <Modal
        active={active}
        size="medium"
        modalTitle={modalTitle}
        quickDismiss={false}
        variant={isMobile ? 'full-screen' : 'normal'}
        toggler={dismissCall}
        cleanup={() => {
          setFileStatus([])
          control._reset()
        }}
        className="pt-14 md:max-w-[32rem]"
      >
        <div
          className={classNames(
            'flex flex-col md:mt-0 md:min-h-72 md:min-w-[28rem] pt-4',
            !isPropertySelected && 'justify-center pt-8 md:pt-0 md:min-h-[10rem]'
          )}
        >
          {userHasMultipleProperties && (
            <div
              className={classNames(
                isPropertySelected && 'hidden',
                !isMobile && 'fixed inset-0 top-auto bottom-auto left-auto right-auto w-[28rem]'
              )}
            >
              <Listbox
                label="Property"
                control={control}
                values={currentJourneyProperties.map((property) => ({
                  name: property.address,
                  value: property.propertyId,
                }))}
                defaultEmpty
                required={true}
                name="property"
              />
            </div>
          )}
          {(!userHasMultipleProperties || isPropertySelected) && (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <div className="mb-4">
                <FileSelector<FileStatus>
                  files={fileStatus}
                  setFiles={setFileStatus}
                  text={'Select or Drop file(s) here'}
                  multiple={true}
                  variant="generic"
                />
              </div>
              <div>
                {fileStatus.map((f) => (
                  <div key={f.fileId}>
                    <GenericFile
                      fileId={f.fileId}
                      file={f.file}
                      onDelete={handleDelete}
                      onComplete={handleComplete}
                      formMethods={formMethods}
                      hbjId={activeHbjId!}
                      pbId={currentProperty || currentJourneyProperties[0].propertyId}
                    />
                  </div>
                ))}
              </div>
              <div className="flex justify-center p-8 md:p-0 md:pt-4">
                <Button
                  type={'submit'}
                  disabled={!isValid || isSubmitLoading}
                  size={isMobile ? 'full' : 'md'}
                  className="absolute bottom-0 w-full md:relative md:w-auto"
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  )
}
